import React from "react";
import PropTypes from "prop-types";
import HomeIcon from "@material-ui/icons/Home";
import Fab from "@material-ui/core/Fab";
import { Link } from "gatsby";
import Carousel from "nuka-carousel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  paper: {
    padding: `30px auto 30px auto`,
  },
  paperSlide: {
    padding: `5px 15px 5px 15px`,
    background: "#F1F3F4",
  },
  home: {
    background: "#F50057", //HGA red
    "&:hover": {
      background: "#BA0948", //darken HGA red
    },
    color: "#FFFFFF",
    position: "fixed",
    bottom: "20px",
    right: "15px",
  },
  gridItem: {
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

class CounterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
    };
  }

  render() {
    const {
      data: { allStrapiCountertype },
      classes,
    } = this.props;
    return (
      <Layout>
        <SEO title="Info Antrian" />
        <Grid container direction="row-reverse" justify="center" spacing={2}>
          <Grid item xs={12} sm={5} md={8} className={classes.gridItem}>
            <Paper square={true} className={classes.paper}>
              <br />
              <Typography variant="h4" align="center">
                <strong>Panggilan:</strong>
                <br />
                Nomor Antrian
              </Typography>
              <br />
              <h1 align="center">C005</h1>
              <br />
              <Typography variant="h3" align="center">
                Ke:
                <br />
                <strong>Loket 1</strong>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5} md={3} className={classes.gridItem}>
            <Carousel
              vertical
              frameOverflow="100px"
              withoutControls={true}
              wrapAround={true}
              autoplay={true}
              autoplayReverse={true}
              slidesToShow={3}
              slidesToScroll={1}
              cellSpacing={130}
              speed={2000}
            >
              {allStrapiCountertype.nodes.map(type =>
                type.counters.map(counter => (
                  <Paper
                    square={true}
                    className={classes.paperSlide}
                    key={counter.id}
                  >
                    <h2 align="center">{counter.name}</h2>
                    <Typography align="center">No. Antrian:</Typography>
                    <Typography variant="h2" align="center">
                      <strong>C003</strong>
                    </Typography>
                  </Paper>
                ))
              )}
            </Carousel>
          </Grid>
        </Grid>
        <Link to="/">
          <Fab
            aria-label="Home"
            size="large"
            className={this.props.classes.home}
          >
            <HomeIcon />
          </Fab>
        </Link>
      </Layout>
    );
  }
}

CounterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CounterPage);

export const pageQuery = graphql`
  query MonitorQuery {
    allStrapiCountertype {
      nodes {
        counters {
          id
          name
        }
        id
        name
      }
    }
  }
`;
